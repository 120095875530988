export { matchers } from "/.svelte-kit/generated/client/matchers.js";

export const nodes = [
	() => import("/.svelte-kit/generated/client/nodes/0.js"),
	() => import("/.svelte-kit/generated/client/nodes/1.js"),
	() => import("/.svelte-kit/generated/client/nodes/2.js"),
	() => import("/.svelte-kit/generated/client/nodes/3.js"),
	() => import("/.svelte-kit/generated/client/nodes/4.js"),
	() => import("/.svelte-kit/generated/client/nodes/5.js"),
	() => import("/.svelte-kit/generated/client/nodes/6.js"),
	() => import("/.svelte-kit/generated/client/nodes/7.js"),
	() => import("/.svelte-kit/generated/client/nodes/8.js"),
	() => import("/.svelte-kit/generated/client/nodes/9.js"),
	() => import("/.svelte-kit/generated/client/nodes/10.js"),
	() => import("/.svelte-kit/generated/client/nodes/11.js"),
	() => import("/.svelte-kit/generated/client/nodes/12.js"),
	() => import("/.svelte-kit/generated/client/nodes/13.js"),
	() => import("/.svelte-kit/generated/client/nodes/14.js"),
	() => import("/.svelte-kit/generated/client/nodes/15.js"),
	() => import("/.svelte-kit/generated/client/nodes/16.js"),
	() => import("/.svelte-kit/generated/client/nodes/17.js"),
	() => import("/.svelte-kit/generated/client/nodes/18.js"),
	() => import("/.svelte-kit/generated/client/nodes/19.js"),
	() => import("/.svelte-kit/generated/client/nodes/20.js"),
	() => import("/.svelte-kit/generated/client/nodes/21.js"),
	() => import("/.svelte-kit/generated/client/nodes/22.js"),
	() => import("/.svelte-kit/generated/client/nodes/23.js"),
	() => import("/.svelte-kit/generated/client/nodes/24.js"),
	() => import("/.svelte-kit/generated/client/nodes/25.js"),
	() => import("/.svelte-kit/generated/client/nodes/26.js"),
	() => import("/.svelte-kit/generated/client/nodes/27.js"),
	() => import("/.svelte-kit/generated/client/nodes/28.js"),
	() => import("/.svelte-kit/generated/client/nodes/29.js"),
	() => import("/.svelte-kit/generated/client/nodes/30.js"),
	() => import("/.svelte-kit/generated/client/nodes/31.js"),
	() => import("/.svelte-kit/generated/client/nodes/32.js"),
	() => import("/.svelte-kit/generated/client/nodes/33.js"),
	() => import("/.svelte-kit/generated/client/nodes/34.js"),
	() => import("/.svelte-kit/generated/client/nodes/35.js"),
	() => import("/.svelte-kit/generated/client/nodes/36.js"),
	() => import("/.svelte-kit/generated/client/nodes/37.js"),
	() => import("/.svelte-kit/generated/client/nodes/38.js"),
	() => import("/.svelte-kit/generated/client/nodes/39.js"),
	() => import("/.svelte-kit/generated/client/nodes/40.js"),
	() => import("/.svelte-kit/generated/client/nodes/41.js"),
	() => import("/.svelte-kit/generated/client/nodes/42.js"),
	() => import("/.svelte-kit/generated/client/nodes/43.js"),
	() => import("/.svelte-kit/generated/client/nodes/44.js"),
	() => import("/.svelte-kit/generated/client/nodes/45.js"),
	() => import("/.svelte-kit/generated/client/nodes/46.js"),
	() => import("/.svelte-kit/generated/client/nodes/47.js"),
	() => import("/.svelte-kit/generated/client/nodes/48.js"),
	() => import("/.svelte-kit/generated/client/nodes/49.js"),
	() => import("/.svelte-kit/generated/client/nodes/50.js"),
	() => import("/.svelte-kit/generated/client/nodes/51.js"),
	() => import("/.svelte-kit/generated/client/nodes/52.js"),
	() => import("/.svelte-kit/generated/client/nodes/53.js"),
	() => import("/.svelte-kit/generated/client/nodes/54.js"),
	() => import("/.svelte-kit/generated/client/nodes/55.js"),
	() => import("/.svelte-kit/generated/client/nodes/56.js"),
	() => import("/.svelte-kit/generated/client/nodes/57.js"),
	() => import("/.svelte-kit/generated/client/nodes/58.js"),
	() => import("/.svelte-kit/generated/client/nodes/59.js"),
	() => import("/.svelte-kit/generated/client/nodes/60.js"),
	() => import("/.svelte-kit/generated/client/nodes/61.js"),
	() => import("/.svelte-kit/generated/client/nodes/62.js"),
	() => import("/.svelte-kit/generated/client/nodes/63.js"),
	() => import("/.svelte-kit/generated/client/nodes/64.js"),
	() => import("/.svelte-kit/generated/client/nodes/65.js"),
	() => import("/.svelte-kit/generated/client/nodes/66.js"),
	() => import("/.svelte-kit/generated/client/nodes/67.js"),
	() => import("/.svelte-kit/generated/client/nodes/68.js"),
	() => import("/.svelte-kit/generated/client/nodes/69.js"),
	() => import("/.svelte-kit/generated/client/nodes/70.js"),
	() => import("/.svelte-kit/generated/client/nodes/71.js"),
	() => import("/.svelte-kit/generated/client/nodes/72.js"),
	() => import("/.svelte-kit/generated/client/nodes/73.js"),
	() => import("/.svelte-kit/generated/client/nodes/74.js"),
	() => import("/.svelte-kit/generated/client/nodes/75.js"),
	() => import("/.svelte-kit/generated/client/nodes/76.js"),
	() => import("/.svelte-kit/generated/client/nodes/77.js"),
	() => import("/.svelte-kit/generated/client/nodes/78.js"),
	() => import("/.svelte-kit/generated/client/nodes/79.js"),
	() => import("/.svelte-kit/generated/client/nodes/80.js"),
	() => import("/.svelte-kit/generated/client/nodes/81.js"),
	() => import("/.svelte-kit/generated/client/nodes/82.js"),
	() => import("/.svelte-kit/generated/client/nodes/83.js"),
	() => import("/.svelte-kit/generated/client/nodes/84.js"),
	() => import("/.svelte-kit/generated/client/nodes/85.js"),
	() => import("/.svelte-kit/generated/client/nodes/86.js"),
	() => import("/.svelte-kit/generated/client/nodes/87.js"),
	() => import("/.svelte-kit/generated/client/nodes/88.js"),
	() => import("/.svelte-kit/generated/client/nodes/89.js"),
	() => import("/.svelte-kit/generated/client/nodes/90.js"),
	() => import("/.svelte-kit/generated/client/nodes/91.js"),
	() => import("/.svelte-kit/generated/client/nodes/92.js")
];

export const server_loads = [0,2,6,13,7,8,10,11,12,4,5];

export const dictionary = {
		"/": [~14],
		"/(app)/account/cash": [22,[2],[3]],
		"/(app)/account/import": [23,[2],[3]],
		"/(app)/account/mints": [24,[2],[3]],
		"/(app)/account/pass": [25,[2],[3]],
		"/(app)/account/savings": [26,[2],[3]],
		"/(app)/account/seed": [27,[2],[3]],
		"/(app)/account/setup": [28,[2],[3]],
		"/(app)/account/[id]": [~21,[2],[3]],
		"/(app)/admin": [~29,[2,6],[3]],
		"/(app)/apps/new": [~32,[2],[3]],
		"/(app)/apps/[pubkey]": [~30,[2],[3]],
		"/(app)/apps/[pubkey]/payments": [~31,[2],[3]],
		"/(static)/bounties": [84,[13]],
		"/(static)/docs": [~85,[13]],
		"/(app)/ecash/[id]/[...version]/swap": [~35,[2,7],[3]],
		"/(app)/ecash/[id]/[...version]": [~34,[2,7],[3]],
		"/(app)/e/[id]": [~33,[2],[3]],
		"/(app)/firehose": [36,[2],[3]],
		"/(static)/forgot": [~86,[13]],
		"/(app)/fund/[id]": [~37,[2],[3]],
		"/(app)/fund/[id]/access": [~38,[2],[3]],
		"/(app)/fund/[id]/sweep": [~39,[2],[3]],
		"/(app)/fund/[id]/withdraw": [~40,[2],[3]],
		"/(app)/invoices": [~47,[2],[3]],
		"/(app)/invoice": [~41,[2],[3]],
		"/(app)/invoice/sent": [46,[2],[3]],
		"/(app)/invoice/[id]": [~42,[2,8],[3]],
		"/(app)/invoice/[id]/memo": [~43,[2,8],[3]],
		"/(app)/invoice/[id]/paid": [44,[2,8],[3]],
		"/(app)/invoice/[id]/tip": [~45,[2,8],[3]],
		"/(app)/items/create": [~49,[2],[3]],
		"/(app)/items/[id]": [~48,[2],[3]],
		"/(app)/ln/withdrawal/sent": [51,[2],[3]],
		"/(app)/ln/[lnurl]": [~50,[2],[3]],
		"/(static)/login": [~87,[13]],
		"/(app)/logout": [~52,[2],[3]],
		"/map": [~92],
		"/(app)/messages": [~53,[2],[3]],
		"/(app)/messages/[recipient]": [~54,[2],[3]],
		"/(app)/payments/[...page]": [~58,[2],[3]],
		"/(app)/payment/[id]": [~56,[2],[3]],
		"/(app)/payment/[id]/plain": [~57,[2],[3]],
		"/(app)/pay/[username]/[...amount]": [~55,[2],[3]],
		"/(app)/printer": [~59,[2],[3]],
		"/(static)/privacy": [88,[13]],
		"/(app)/qr/create": [61,[2],[3]],
		"/(app)/qr/[text]": [~60,[2],[3]],
		"/(app)/receive/[...token]": [~62,[2],[3]],
		"/(static)/register": [~89,[13]],
		"/(static)/removal": [90,[13]],
		"/(app)/reset/[code]": [~63,[2],[3]],
		"/(app)/scan": [64,[9]],
		"/(app)/send/bitcoin/[address]": [~66,[2,10,11],[3]],
		"/(app)/send/bitcoin/[address]/[amount]/[...feeRate]": [~67,[2,10,11],[3]],
		"/(app)/send/ecash": [~68,[2,10],[3]],
		"/(app)/send/ecash/[request]": [~69,[2,10],[3]],
		"/(app)/send/fund/[...name]/[...amount]": [~70,[2,10],[3]],
		"/(app)/send/invoice/[id]": [~71,[2,10],[3]],
		"/(app)/send/lightning/[payreq]/[...memo]": [~72,[2,10],[3]],
		"/(app)/send/zap/[id]/[...amount]": [~73,[2,10],[3]],
		"/(app)/send/[...text]": [~65,[2,10],[3]],
		"/(app)/sent/[id]": [~74,[2],[3]],
		"/(app)/settings": [75,[2,12],[3]],
		"/(app)/settings/account": [~76,[2,12],[3]],
		"/(app)/settings/nostr": [~77,[2,12],[3]],
		"/(app)/settings/preferences": [~78,[2,12],[3]],
		"/(app)/settings/security": [~79,[2,12],[3]],
		"/(app)/settings/shopify": [~80,[2,12],[3]],
		"/(app)/sign": [~81,[2],[3]],
		"/(static)/support": [91,[13]],
		"/(app)/thread/[id]": [~82,[2],[3]],
		"/(app)/verify/[code]": [~83,[2],[3]],
		"/(app)/[username]/(profile)": [~15,[2,4,5],[3]],
		"/(app)/[username]/(profile)/followers": [~16,[2,4,5],[3]],
		"/(app)/[username]/(profile)/follows": [~17,[2,4,5],[3]],
		"/(app)/[username]/(profile)/notes": [~18,[2,4,5],[3]],
		"/(app)/[username]/(profile)/notes/new": [19,[2,4,5],[3]],
		"/(app)/[username]/receive": [~20,[2,4],[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from "/.svelte-kit/generated/root.js";